import { Fragment, useState, useMemo, useCallback } from 'react';
import {
  Button,
  Menu,
  MenuItem,
  ButtonGroup,
  Icon,
  MenuDivider,
} from '@blueprintjs/core';
import { IndentedMenuItem, NoItems, StyledMenu } from './styled';
import { firstLetterUppercase } from '../../utils/firstLetterUppercase';

export const getSubMenuTaxonomyButtonText = (
  maxLength,
  selectedItems,
  text,
) => {
  let selectedLength = 0;
  selectedItems.forEach((item) => {
    selectedLength += item.values.length;
  });
  if (maxLength === selectedLength) {
    return text ? text : `All (${selectedLength} of ${maxLength})`;
  } else {
    return `${selectedLength} of ${maxLength}`;
  }
};

export const AuraSubMenu = ({
  allItems, // [{ key:string, values: string[]}]
  selectedItems,
  updateSelectedItems,
  noItemsText,
  itemsTypeText,
  canBeEmpty,
  disableMultipleKeys,
}) => {
  const [filterSelection, setFilterSelection] = useState(selectedItems);

  const handleFilterItemsChange = useCallback(
    (key, value) => {
      if (disableMultipleKeys) {
        if (filterSelection.length === 0 || filterSelection[0].key !== key) {
          setFilterSelection([{ key, values: [value] }]);
        } else {
          // add or remove value
          if (filterSelection[0].values.includes(value)) {
            setFilterSelection([
              {
                key,
                values: filterSelection[0].values.filter((v) => v !== value),
              },
            ]);
          } else {
            setFilterSelection([
              { key, values: [...filterSelection[0].values, value] },
            ]);
          }
        }
      } else {
        const keyFound = filterSelection.find((i) => i.key === key);
        const hasValue = keyFound && keyFound.values.includes(value);
        if (keyFound && hasValue && keyFound.values.length > 1) {
          // remove just value
          keyFound.values = keyFound.values.filter((v) => v !== value);

          setFilterSelection([
            ...filterSelection.filter((i) => i.key !== key),
            { ...keyFound, values: keyFound.values.filter((v) => v !== value) },
          ]);
        } else if (keyFound && hasValue && keyFound.values.length === 1) {
          // remove key
          setFilterSelection(filterSelection.filter((i) => i.key !== key));
        } else if (keyFound && !hasValue) {
          keyFound.values.push(value);
          setFilterSelection([
            ...filterSelection.filter((i) => i.key !== key),
            keyFound,
          ]);
        } else {
          setFilterSelection([...filterSelection, { key, values: [value] }]);
        }
      }
    },
    [filterSelection, disableMultipleKeys],
  );

  const renderedItems = useMemo(() => {
    return allItems.map(({ key, values }, i) => (
      <Fragment key={key}>
        {key !== '' && (
          <MenuItem
            style={{ pointerEvents: 'none', cursor: 'default' }}
            key={key}
            text={firstLetterUppercase(key)}
            shouldDismissPopover={false}
          />
        )}
        {values.map((value) => (
          <IndentedMenuItem
            key={key + value}
            text={firstLetterUppercase(value.split(':').join(' '))}
            shouldDismissPopover={false}
            label={
              filterSelection.find(
                (i) =>
                  i.key === key &&
                  i.values &&
                  i.values.length > 0 &&
                  i.values.includes(value),
              ) ? (
                <Icon icon="tick" />
              ) : null
            }
            onClick={() => handleFilterItemsChange(key, value)}
          />
        ))}
        {i !== allItems.length - 1 && <MenuDivider />}
      </Fragment>
    ));
  }, [allItems, filterSelection]);

  return (
    <Menu>
      <StyledMenu>
        {renderedItems.length > 0 ? (
          renderedItems
        ) : (
          <NoItems>{noItemsText}</NoItems>
        )}
      </StyledMenu>
      {allItems.length > 0 && (
        <Fragment>
          <ButtonGroup fill={true}>
            <Button
              icon="cross"
              disabled={filterSelection.length === 0}
              onClick={() => setFilterSelection([])}
            >
              Clear selection
            </Button>
          </ButtonGroup>
          <Button
            intent="success"
            style={{ marginTop: '0.25rem' }}
            fill={true}
            disabled={
              typeof canBeEmpty !== 'undefined' && canBeEmpty
                ? false
                : filterSelection.length === 0
            }
            onClick={() => updateSelectedItems(filterSelection)}
          >
            Filter {firstLetterUppercase(itemsTypeText)}
          </Button>
        </Fragment>
      )}
    </Menu>
  );
};
