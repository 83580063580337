import styled from 'styled-components';
import { Panel, PanelHeader } from '../panel';
import { Button, Icon, Slider, Spinner, Tag } from '@blueprintjs/core';
import { breakpoints } from '../../styles/variables';

export const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  max-width: var(--widget-max-width);
  margin: auto;
`;
export const HeatmapWrapper = styled(Wrapper)`
  display: flex;
  flex-flow: column;
  height: calc(100vh - 4rem);
`;
export const HeatmapFilterWrapper = styled(Wrapper)`
  flex: 0 1 auto;
  margin: 0;
  overflow-x: clip;
`;

export const StyledPanel = styled(Panel)`
  margin-top: 1rem;
`;

export const LoadingSpinner = styled(Spinner)`
  margin: 2rem auto;
`;

export const NoHeatmapsMessage = styled.p`
  margin: 2rem 1rem;
  text-align: center;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
`;
export const RowReverse = styled(Row)`
  flex-direction: row-reverse;
`;
export const StyledPanelHeader = styled(PanelHeader)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    justify-content: center;
  }
`;
export const SelectorLabel = styled.p`
  margin: 0 1rem 0 0;
`;

export const ChevronButton = styled(Button)`
  ${({ marginDirection }) =>
    marginDirection
      ? `margin-${marginDirection}: 0.5rem`
      : 'margin-right: 0.5rem'}
`;

export const Container = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  max-width: max-content;
  flex-wrap: wrap;
  gap: 8px;
`;

export const Spacer = styled.span`
  width: 1rem;
`;

export const GridContainer = styled.div`
  width: inherit;
  margin-top: 1rem;
  flex: 1 1 auto;
  overflow-y: auto;
`;

export const Grid = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  grid-auto-rows: 1fr;
  ${({ columns }) => `grid-template-columns: repeat(${columns}, 1fr);`}

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    display: block;
  }
`;
export const Cell = styled.div`
  ${({ size }) =>
    `grid-column: ${typeof size === 'undefined' ? `span 1;` : `span ${size};`}`}
  border: 0 var(--panel-border);
  margin: 1rem auto;
  border-radius: var(--widget-border-radius);
  padding: 0.5rem;
  background: var(--panel-background);
  box-shadow: var(--widget-box-shadow);
  ${({ size }) => `min-height: ${size * 128}px;`}
  ${({ size }) => (size === 4 ? 'width: 70%;' : 'width: 100%')}
`;
export const TagRow = styled.div`
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 3px;
`;

export const PinnedItemsContainer = styled.div`
  background: var(--gray);
  margin: 16px;
`;

export const StyledTag = styled(Tag)`
  ${({ bgColor }) => bgColor && `background-color: ${bgColor};`}
`;

export const GridControls = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

export const PaginationItem = styled.p`
  ${({ active }) => active && 'font-weight: bold;'}
  padding: 0.5rem;
  margin: 0;
  cursor: pointer;
  &:hover {
    background: var(--gray);
  }
`;

export const PageItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const BtnIcon = styled(Icon)`
  cursor: pointer;
`;

export const LoadHeatmapsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin: 1rem;
`;

export const SliderContainer = styled.div`
  position: relative;
  width: 70%;
  ${({ maxWidth }) => `max-width: ${maxWidth};`}
  // margin is there to make labels fit nicely
  margin-left: 64px;
  margin-right: 16px;
  height: inherit;
`;

export const HeatmapSlider = styled(Slider)`
  > .bp3-slider-handle {
  position: absolute;
    > .bp3-slider-label {
      margin: 0;
      width: max-content;
      padding: 2px 12px;
      position: relative;
      left: 50%;
      top: 180%;
      transform: translate(-50%, -50%);
    }
  }
  }
  > .bp3-slider-axis {
    > .bp3-slider-label{
          display: none;
        :first-child {  
          display: block;
        }
        :last-child {  
          white-space: nowrap;
          display: block;
        }
      }
  }
`;

export const MarkationGrid = styled.div`
  position: absolute;
  display: grid;
  grid-template-rows: 1fr;
  ${({ numColumns }) => `grid-template-columns: repeat(${numColumns}, 1fr);`}
  width: 100%;
`;
export const MarkationTick = styled.div`
  position: relative;
  height: 25px;
  ${({ left }) => `left: calc(${left} - 1px);`}
  width: 2px;
  background: ${({ hidden }) => (hidden ? 'none' : 'var(--gray)')};
  top: -5px;
`;

export const MarkationCell = styled.div`
  grid-column: span 1;
  height: 16px;
`;

export const CenteredOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-color);
  border-radius: var(--widget-border-radius);
  height: 100%;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 2.5rem); /* Subtract padding and TagRow height */
  ${({ size }) => `min-height: ${Math.max(100, 150 + (75 * (size || 1)))}px;`}
`;
