import { useCallback, useEffect, useState, useMemo } from 'react';
import {
  Grid,
  GridContainer,
  LoadingSpinner,
  NoHeatmapsMessage,
} from './styled';
import HeatmapCard from './hetmap-card';
import HeatmapGridControls from './heatmap-grid-controls';
import { useHeatmapDisplayGridContext } from './useFilterOptionsContext';
import { HEATMAPS_TEXT_CONTENT } from '../../constants/heatmaps';
import moment from 'moment';

const perPageOptions = [8, 16, 32];
const defaultPerPage = 16;
type Props = {
  items: Array,
  isLoading: boolean,
};

const HeatmapDisplayGrid = ({ items, isLoading }: Props) => {
  const { cardSize, selectedDate, taxonomies, prevFetchedFilterOptions } =
    useHeatmapDisplayGridContext();

  const [gridNavigationState, setNavigationState] = useState({
    currentPage: 1,
    itemsPerPage: defaultPerPage,
    maxPage: Math.ceil(items.length / defaultPerPage),
  });

  useEffect(() => {
    setNavigationState((prev) => ({
      currentPage:
        prev.currentPage >= Math.ceil(items.length / prev.itemsPerPage)
          ? 1
          : prev.currentPage,
      itemsPerPage: prev.itemsPerPage || defaultPerPage,
      maxPage: Math.ceil(items.length / prev.itemsPerPage || defaultPerPage),
    }));
  }, [items]);

  const updateNavigationState = useCallback(
    (key, value) => {
      switch (key) {
        case 'itemsPerPage':
          setNavigationState((prev) => ({
            currentPage:
              prev.currentPage >= Math.ceil(items.length / value)
                ? 1
                : prev.currentPage,
            itemsPerPage: value,
            maxPage: Math.ceil(items.length / value),
          }));
          break;
        case 'currentPage':
          setNavigationState((prev) => ({ ...prev, currentPage: value }));
          break;
        default:
          break;
      }
    },
    [items],
  );

  const handleUpdateNavigationState = useCallback(updateNavigationState, [
    items,
  ]);

  const displayedItems = useMemo(() => {
    const start =
      (gridNavigationState.currentPage - 1) * gridNavigationState.itemsPerPage;
    return items.slice(start, start + gridNavigationState.itemsPerPage);
  }, [gridNavigationState, items]);

  const gridControls = useMemo(
    () =>
      gridNavigationState?.maxPage > 1 ? (
        <HeatmapGridControls
          displayOptions={perPageOptions}
          currentPage={gridNavigationState.currentPage}
          itemsPerPage={gridNavigationState.itemsPerPage}
          maxPage={gridNavigationState.maxPage}
          updateNavigationState={handleUpdateNavigationState}
        />
      ) : null,
    [gridNavigationState, perPageOptions],
  );

  if (isLoading) return <LoadingSpinner />;
  if (!items || items.length === 0)
    return (
      <NoHeatmapsMessage>{HEATMAPS_TEXT_CONTENT.NO_HEATMAPS}</NoHeatmapsMessage>
    );
  if (
    typeof prevFetchedFilterOptions !== 'undefined' &&
    (moment(selectedDate).isBefore(prevFetchedFilterOptions.start) ||
      moment(selectedDate).isAfter(prevFetchedFilterOptions.end))
  )
    return (
      <NoHeatmapsMessage>
        {HEATMAPS_TEXT_CONTENT.OUT_OF_DATE_RANGE}
      </NoHeatmapsMessage>
    );
  return (
    <GridContainer>
      <Grid columns={4}>
        {displayedItems.map((item) => (
          <HeatmapCard
            key={item.key}
            size={cardSize}
            {...item}
            visibleTaxonomies={taxonomies}
            selectedDate={selectedDate}
          />
        ))}
      </Grid>
      {gridControls}
    </GridContainer>
  );
};

export default HeatmapDisplayGrid;
