export const HEATMAPS_TEXT_CONTENT = {
  NO_CAMERAS: 'No camera placements found',
  OUT_OF_DATE_RANGE:
    'Selected date range is outside of the available date range, please load heatmaps for the full date range.',
  NO_HEATMAPS: 'No heatmaps found for the selected options.',
};

export const filterTypesArray = [
  {
    key: 'lingerAccumulatedTime',
    display: 'Dwell: Total time',
  },
  // { //hidden at Dan's request
  //   key: 'lingerAverageTime',
  //   display: 'Dwell: Average time',
  // },
  { key: 'path', display: 'Popular Paths' },
  // { key: 'thumbnail', display: 'Camera Preview' },
  // This requires a change in the SQL - API to point to external.thumbnails instead of external.heatmaps
];
export const filterBreakdownsArray = [
  { key: 'all', display: 'All Visitors' },
  { key: 'ages', display: 'Ages' },
  { key: 'genders', display: 'Genders' },
  { key: 'roles', display: 'Roles' },
];
export const MAX_LOCATIONS = 350;
export const MAX_RECORDINGS = 350;
export const MAX_ENTITIES = 350;
export const MAX_DAYS = 31;
export const INITIALLY_SELECTED_LOCATIONS = 10;

export const HEATMAP_KEY_COLOURS = {
  location: '#004777',
  recording: '#72195A',
  taxonomy: '#998FC7',
};

export const ENTITY_TYPES = {
  LOCATION: 'location',
  RECORDING: 'recording',
};

export const ERROR_MESSAGES = {
  REQUEST_TOO_LARGE:
    'Request is too large. Please reduce the number of locations/recordings or select a shorter date range.',
  REQUEST_TOO_LARGE_WITH_TAXONOMIES:
    'Request is too large. Please reduce the number of locations/recordings/taxonomies and/or select a shorter date range.',
  FETCH_ERROR: 'Error fetching heatmaps',
  INVALID_FILTER_OPTIONS: 'Invalid filter options',
};

export const INFO_MESSAGES = {
  LOCATION_LIST_SHORTENED: `Selected ${INITIALLY_SELECTED_LOCATIONS} locations to reduce load times.`,
  PERIOD_TOO_LONG: `Selected period is longer than ${MAX_DAYS} days.`,
};

export const TOAST_TIMEOUTS = {
  SHORT: 2500,
  LONG: 5000,
};

export const UPDATE_FILTER_OPTIONS_ACTIONS = {
  LOCATIONS: 'LOCATIONS',
  RECORDINGS: 'RECORDINGS',
  TAXONOMIES: 'TAXONOMIES',
  BREAKDOWN: 'BREAKDOWN',
  SELECTED_PERIOD: 'SELECTED_PERIOD',
  SELECTED_DATE: 'SELECTED_DATE',
  CARD_SIZE: 'CARD_SIZE',
  RESET: 'RESET',
  TYPE: 'TYPE',
};

export const REDUCER_ACTIONS = {
  SET_FILTER_OPTIONS: 'SET_FILTER_OPTIONS',
  SET_CARD_SIZE: 'SET_CARD_SIZE',
  SET_PREV_FETCHED_FILTER_OPTIONS: 'SET_PREV_FETCHED_FILTER_OPTIONS',
  SET_SHOULD_FETCH_HEATMAPS: 'SET_SHOULD_FETCH_HEATMAPS',
  SET_HEATMAPS: 'SET_HEATMAPS',
  SET_FILTERED_RECORDINGS: 'SET_FILTERED_RECORDINGS',
  SET_ENABLED_LOCATIONS: 'SET_ENABLED_LOCATIONS',
  SET_TAXONOMY_LIST: 'SET_TAXONOMY_LIST',
  SET_CTX: 'SET_CTX',
  SET_TAXONOMY_KEY_STRINGS: 'SET_TAXONOMY_KEY_STRINGS',
  SET_IS_QUERY_SUBSET: 'SET_IS_QUERY_SUBSET',
  SET_SELECTED_DATE: 'SET_SELECTED_DATE',
};
